<template>
    <div class="zt-page-content" ref="load">
        <div v-if="!previewFlag && showImgs" style="min-width:1200px;overflow:auto;">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm1" label-width="120px">
                
                <div class="zt-block">
                    <div class="zt-block-head">基本信息</div>
                    <div class="zt-block-content">
                        <el-form-item label="商品名称" prop="name">
                            <el-input v-model="ruleForm.name" maxlength="30" placeholder="填写商品名称"></el-input>
                            <span class="font14-grey1" style="padding-left:10px;line-height:20px;position:relative;top:10px;"
                                >{{(ruleForm.name || '').length}}/30</span>
                        </el-form-item>
                        <el-form-item label="商品简称">
                            <el-input v-model="ruleForm.sname" maxlength="30" placeholder="填写商品简称"></el-input>
                            <span class="font14-grey1" style="padding-left:10px;line-height:20px;position:relative;top:10px;"
                                >{{(ruleForm.sname || '').length}}/30</span>
                        </el-form-item>
                        <!-- <el-form-item label="商品型号">
                            <el-input v-model="ruleForm.module_no" placeholder="填写商品型号"></el-input>
                        </el-form-item> -->
                        <el-form-item label="商品分类名称">
                            <div class="flex">
                                <div style="margin-right:32px;">
                                    <!-- <el-select  v-model="ruleForm.set_no"  placeholder="请选择套系">
                                        <el-option v-for="item in series" :key="item.set_no" :label="item.name" :value="item.set_no">
                                        </el-option>
                                        <el-option :label="'未分类'" :value="''">未分类</el-option>
                                    </el-select> -->
                                    <el-cascader :options="series" @change="handleCat" :show-all-levels="false" :props="{value:'id',label:'name',emitPath:false}"
                                        v-model="ruleForm.set_no" placeholder="请选择商品分类">
                                    </el-cascader>
                                </div>
                                <div>
                                    <!-- <el-button style="margin-right:32px;" @click="selectSets()" type="primary" plain>选择已有套系</el-button> -->
                                    <router-link :to="{ path:'/desgin/txgl',query:{idx:price_type} }" target="_blank">
                                        <el-button type="primary" plain>新建商品分类</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="单品风格" prop="style_no">
                            <el-select v-model="ruleForm.style_no" placeholder="请选择风格">
                                <el-option v-for="item in styles" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="商品描述">
                            <el-input v-model="ruleForm.intro" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="单品类目" prop="cat_id">
                            <el-cascader :options="cats" :disabled="product_no&&catId?true:false" @change="handleCat" :show-all-levels="false" :props="{value:'id',label:'name',emitPath:false}"
                                v-model="ruleForm.cat_id" placeholder="请选择类目">
                            </el-cascader>
                        </el-form-item>
                        <div v-if="ruleForm.attr && ruleForm.attr.length>0 && ruleForm.attr[0].id">
                            <el-form-item class="block-bg" :style="(idx+1)==ruleForm.attr.length ? 'margin-bottom:0;' : ''"
                                v-for="(attr,idx) in ruleForm.attr" :key=idx 
                                :prop="attr.nullable ? ('attr.' + idx + '.values') : ''"
                                :rules="attr.nullable ? rules.attr : []"
                                :label="attr.name">
                                <div v-if="attr.type=='single' && attr.items && attr.items.length>0">
                                    <el-select v-model="attr.values" placeholder="请选择">
                                        <el-option v-for="item in attr.items" :key="item.id" :label="item.value" :value="item.id"></el-option>
                                    </el-select>
                                </div>
                                <div v-if="attr.type=='mutil' && attr.items && attr.items.length>0">
                                    <el-checkbox-group v-model="attr.values">
                                        <el-checkbox v-for="item in attr.items" :key="item.id" :label="item.id" :value="item.id">{{item.value}}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="zt-block">
                    <div class="zt-block-head">设计信息</div>
                    <div class="zt-block-content">
                        <el-form-item label="设计尺寸">
                            <DesignSize v-model="ruleForm.dimension"></DesignSize>
                        </el-form-item>
                        <el-form-item style="padding-top:10px;" label="设计理念">
                            <div style="position:relative;max-width:500px;">
                                <el-input type="textarea" v-model="ruleForm.design_idea" maxlength="120" rows="5" style="max-width:500px;"></el-input>
                                <div class="font14-grey1" style="position:absolute;right:10px;bottom:6px;">{{(ruleForm.design_idea || '').length}}/120</div>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="zt-block">
                    <div class="zt-block-head">图片与视频</div>
                    <div class="zt-block-content">
                        <el-form-item label="3D 单品渲染图" prop="photo_render">
                            <div>
                                <UploadImgs v-if="showImgs" :limit="5" v-model="ruleForm.photo_render" prefix='product/render'></UploadImgs>
                            </div>
                        </el-form-item>
                        <el-form-item label="标高图">
                            <div>
                                <UploadImgss v-if="showImgs" v-model="ruleForm.photo_size" :limit="10" prefix='product/size'></UploadImgss>
                            </div>
                        </el-form-item>
                        <el-form-item label="素材图" style="margin-bottom:0px;">
                            <div>
                                <UploadImgss v-if="showImgs" v-model="ruleForm.photo_story" :limit="10" prefix='product/story'></UploadImgss>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="zt-block">
                    <div class="zt-block-head">文件</div>
                    <div class="zt-block-content">
                        <el-form-item label="设计CAD文件：" style="margin-bottom:0;">
                            <div style="position:relative;">
                                <UploadCads v-if="showImgs" v-model="ruleForm.photo_cad" prefix='product/cad'></UploadCads>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="zt-block">
                    <div class="zt-block-head" v-if="ruleForm.accessory && ruleForm.accessory.length>0">附件信息</div>
                    <div class="zt-block-content" v-if="ruleForm.accessory && ruleForm.accessory.length>0" style="padding:0 30px;">
                        <div class="list-item" v-for="(item,idx) in ruleForm.accessory" :key="idx">
                            <div style="position:absolute;top:30px;right:0;z-index:999;" class="flex flex-align-center">
                                <div style="font-size:16px;color:#292929;line-height:42px;margin-right:10px">附件{{idx+1}} </div>
                                <div v-if="item.id">
                                    <el-button v-if="item.stat==3" @click="changeSwitch(item,idx)" size="mini" type="text" plain>启用</el-button>
                                    <el-button v-if="item.stat==2" @click="changeSwitch(item,idx)" size="mini" type="text" plain>禁用</el-button>
                                </div>
                                <el-button v-else @click="delAccessory(idx)" style="font-size:14px;color:#FF4D4F;" type="text">
                                    <i class="iconfont icon-icon7"></i> 删除
                                </el-button>
                            </div>
                            <el-form-item label="附件名称" :prop="'accessory.'+ idx +'.name'" :rules="rules.accessoryName">
                                <el-input v-model="item.name" placeholder="请填写附件名称"></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="型号" :prop="'accessory.'+ idx +'.module_no'" :rules="rules.accessoryModuleNo">
                                <el-input v-model="item.module_no" placeholder="请填写型号"></el-input>
                            </el-form-item> -->
                            <el-form-item label="设计尺寸">
                                <DesignSize v-model="item.dimension"></DesignSize>
                            </el-form-item>
                            <el-form-item label="标高图">
                                <div>
                                    <UploadImgss v-if="showImgs" v-model="item.photo_size" :limit="10" prefix='product/size'></UploadImgss>
                                </div>
                            </el-form-item>
                            <el-form-item label="素材图" style="margin-bottom:0px;">
                                <div>
                                    <UploadImgss v-if="showImgs" v-model="item.photo_story" :limit="10" prefix='product/story'></UploadImgss>
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="add-accessory flex flex-align-center flex-pack-center">
                        <div @click="addAccessory()" class="addSeries">
                            <span>+</span>
                            <span>点击添加附件</span>
                        </div>
                    </div>
                </div>
                <!-- <div @click="addStyle()" class="zt-block addSeries">
                    <span>+</span>
                    <span>点击添加附件</span>
                </div> -->
                <div class="zt-block">
                    <div class="zt-block-head">销售信息</div>
                    <div class="zt-block-content">
                        <!-- <el-form-item v-for="(param,idx) of ruleForm.tpl_params" :key="idx" :label="param.text">
                            <el-form-item :prop="'tpl_params.'+ idx +'.value'" :rules="rules.value">
                                <el-input style="width:250px;" v-model="param.value"></el-input>
                            </el-form-item>
                        </el-form-item> -->
                        <el-form-item class="input-red" style="margin-bottom:60px;" prop="price">
                            <div style="position:relative;left:-76px;" class="flex flex-align-center">
                                <div style="font-size:24px;color:#F66F6A;margin-right:10px;">一口价(出售该作品版权的价格)：￥</div>
                                <div style="margin-right:10px;">
                                    <el-input style="max-width:120px;" type="number" v-model="ruleForm.price" min="0"></el-input>
                                </div>
                                <div style="font-size:24px;color:#F66F6A;">元(RMB)</div>
                            </div>
                        </el-form-item>
                        <el-form-item label="合同信息" prop="tpl_id" style="margin-bottom:10px;">
                            <!-- <el-form-item v-for="(param,idx) of ruleForm.tpl_params" :key="idx" :label="param.text">
                                <el-form-item :prop="'tpl_params.'+ idx +'.value'" :rules="rules.value">
                                    <el-input style="width:250px;" v-model="param.value"></el-input>
                                </el-form-item>
                            </el-form-item> -->
                            <div class="tpls flex">
                                <div class="tpls-item" v-for="(tpl,idx) of tpls" :key="idx">
                                    <div>
                                        <i class="iconfont icon-PDF1"></i>
                                    </div>
                                    <div>
                                        <a :href="imgUrl+tpl.view_url" target="_blank">{{tpl.title||'未知'}}</a>
                                        <el-radio @change="setTpl(tpl)" style="padding:0;" v-model="ruleForm.tpl_id" :label="tpl.id"><span></span></el-radio>
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                        <div style="max-width:400px;margin:50px auto 0;">
                            <div v-if="product_no" class="flex flex-justify-around">
                                <el-button @click="preview()" size="small" type="text">
                                    <i style="font-size:14px;position:relative;top:1px;margin-right:5px;" class="iconfont icon-sousuo"></i>
                                    <span>上架预览</span>
                                </el-button>
                                <el-button size="small" type="primary" @click="back()">取消</el-button>
                                <el-button size="small" type="primary" @click="editSubmit(0)">保存</el-button>
                                <el-button size="small" @click="editSubmit(1)" type="primary">上 传</el-button>
                            </div>
                            <div v-else class="flex flex-justify-around">
                                <el-button @click="preview()" size="small" type="text">
                                    <i style="font-size:14px;position:relative;top:1px;margin-right:5px;" class="iconfont icon-sousuo"></i>
                                    <span>上架预览</span>
                                </el-button>
                                <el-button size="small" @click="saveDraft()" type="primary" plain>存草稿</el-button>
                                <el-button size="small" @click="submitShow()" type="primary">上 传</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>

        <ViewDetail v-if="previewFlag && previewData" :previewData="previewData" @back="previewFlag=false"></ViewDetail>

        <el-dialog width="550px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <!-- <SelectTxgl v-if="popTitle=='自定义商品套系'" :type="price_type" @hide="hide"></SelectTxgl> -->
            <!-- <AddTxgl v-if="popTitle=='新增套系'" :form="ruleForm" @hide="hide"></AddTxgl> -->
            <div v-if="popTitle=='温馨提示'">
                <div style="width:80%;margin:0 auto;text-align:center;padding-bottom:20px;">
                    <p style="font-size:16px;color:#292929;">作品发布确认</p>
                    <p style="font-size:14px;color:#808080;">作品发布后，工厂即可在设计超市中浏览和购买，请仔细核对作品详情和价格</p>
                </div>
                <div style="text-align:center;border-top:1px solid #ccc;padding-top:25px;">
                    <el-button @click="submitHide()" style="margin-right:20px;" size="small" type="primary" plain>再修改一下</el-button>
                    <el-button @click="submitForm" size="small" type="primary">确认并发布</el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import store from '@/store';
    import { getCats, getStyles, getSets, getAttrs } from '@/service/item';
    import { listContractTpl } from '@/service/contract';
    import { uploadFile } from '@/service/common';
    import { addPriceProduct,designPriceId,setPriceProduct } from '@/service/design';
    import { fetchTree,imgLayout } from '@/util';
    import UploadImgs from '@/components/upload/UploadImgs.vue';
    import UploadImgss from '@/components/upload/UploadImgss.vue';
    import UploadCads from '@/components/upload/UploadCads.vue';
    // import SelectTxgl from '@/views/desgin/txgl/SelectTxgl.vue';
    // import AddTxgl from '@/views/desgin/txgl/AddTxgl.vue';
    import ViewDetail from './ViewDetail.vue';
    import DesignSize from '@/components/factory/DesignSize.vue';

    export default {
        components:{
            UploadImgs,UploadImgss,UploadCads,
            // SelectTxgl,
            // AddTxgl,
            ViewDetail,
            DesignSize
        },
        props: {},
        computed: {},
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                popTitle:null,
                popVisible:false,
                ruleForm: {
                    name: null,
                    sname: null,
                    module_no:null,
                    intro:null,
                    set_no:null,
                    style_no: null,
                    cat_id: null,
                    design_idea:null,
                    price:null,
                    tpl_id: null,
                    // size: { len: null,wlen: null,height: null, },
                    dimension: [],
                    photo_render:[],
                    photo_cad:[],
                    photo_size:[],
                    photo_story:[],
                    accessory:[],
                    attr: [],
                },
                rules: {
                    name: [{ required: true, message: '填写商品名称', trigger: 'blur' }],
                    // sname: [{ required: true, message: '请填写商品简称', trigger: 'blur' }],
                    module_no: [{ required: true, message: '请填写商品型号', trigger: 'blur' }],
                    // set_no: [{ required: true, message: '请选择套系', trigger: 'blur' }],
                    style_no: [{ required: true, message: '请选择风格', trigger: 'blur' }],
                    cat_id: [{ required: true, message: '请选择类目', trigger: 'blur' }],
                    attr: [{
                        required: true,
                        validator: (rule, value, callback) => {
                            if (value && value.length > 0) {
                                callback();
                            } else if (value && JSON.stringify(value) != '[]') {
                                callback();
                            } else {
                                let _idx = rule.field.split(".")[1];
                                let _name = this.ruleForm.attr[_idx].name;
                                if (!value) {
                                    callback(new Error(`请选择${_name}`));
                                } else {
                                    callback(new Error(`${_name} 至少选择一项!`));
                                }
                            }
                        },
                        trigger: "blur"
                    }],
                    design_idea: [{ required: true, message: '请填写设计理念', trigger: 'blur' }],
                    photo_render: [{ required: true, message: '请上传3D 单品渲染图', trigger: 'blur' }],
                    price: [{ required: true, message: '请填写价格', trigger: 'blur' }],
                    tpl_id: [{ required: true, message: '请选择合同', trigger: 'blur' }],
                    value: [{ required: true, message: '请填写内容', trigger: 'blur' }],
                    accessoryName: [{ required: true, message: '请填写附件名称', trigger: 'blur' }],
                    accessoryModuleNo: [{ required: true, message: '请填写型号', trigger: 'blur' }],
                },
                autoSave: null,
                tpls: [],
                cats: [],
                catList:[],
                styles: [],
                series:[],
                // blacklists:[],
                curParams:[],
                product_no:null,
                catId:null,
                price_type:0,
                showImgs:false,
                previewFlag:false,
                previewData:null,
            }
        },
        async created() {
            if (this.$route.query.set_no) {
                this.ruleForm.set_no=this.$route.query.set_no;
            }
            this.product_no = this.$route.query.id;
            this.tpls = await listContractTpl(1);
            this.catList = await getCats();
            this.cats = fetchTree(this.catList, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
            this.styles = await getStyles();
            this.getSeries();
            // this.blacklists = await getBlacklists(store.state.loginUser.user.comp_id,1);
        },
        mounted() {
            if (this.product_no) {
                designPriceId(this.product_no).then(rst => {
                    console.log(rst);
                    this.catId = rst.cat.id;
                    if (rst) {
                        this.ruleForm = {
                            name: rst.name,
                            sname: rst.sname,
                            module_no:rst.module_no,
                            intro:rst.intro,
                            set_no:rst.set_id,
                            style_no: rst.style.id,
                            cat_id: rst.cat.id,
                            design_idea:rst.design_idea,
                            price:rst.price,
                            tpl_id:rst.contract_tpl_id,
                            // size: rst.size,
                            dimension: rst.dimension,
                            photo_render:rst.photo_render,
                            photo_cad:rst.photo_cad,
                            photo_size:rst.photo_size,
                            photo_story:rst.photo_story,
                            accessory:rst.accessory,
                            attr:rst.attr,
                        };
                        this.get_attrs(this.catId);
                        this.showImgs = true;
                    }
                    console.log(this.ruleForm);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            } else {
                this.showImgs = true;  
            }
        },
        destroyed() {
            if (this.autoSave) clearInterval(this.autoSave);
        },
        methods: {
            // 选择类目
            handleCat(e) {
                console.log(e);
                this.get_attrs(e);
            },
            // 获取类目下面的spu属性
            get_attrs(catId) {
                getAttrs(catId,{hasValue:true}).then(rst => {
                    if (rst && rst.length>0) {
                        rst.forEach(e => {
                            this.$set(e,'values',[]);
                            if (this.ruleForm.attr && this.ruleForm.attr.length>0) {
                                this.ruleForm.attr.forEach((n)=>{
                                    if (e.id == n.name) {
                                        if (e.type=='mutil') {
                                            e.values = n.values;
                                        } else e.values = n.values[0];
                                    }
                                })
                            }
                        });
                    }
                    this.ruleForm.attr = rst;
                    console.log(this.ruleForm.attr);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            back() {
                this.$router.go(-1);
            },
            getSeries() {
                getSets({price_type:this.price_type}).then(rst => {
                    if (rst && rst.length>0) {
                        let series = fetchTree(rst, "", { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'pid' });
                        series.forEach((e)=>{
                            if (e.children && e.children.length>0) {
                                this.series.push(e);
                            }
                        })
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            hide(item) {
                this.popVisible = false;
                if (item) {
                    this.getSeries();
                    this.ruleForm.set_no = item.set_no;
                }
            },
            selectSets() {
                this.popTitle = "自定义商品商品分类";
                this.popVisible = true;
            },
            addSets() {
                this.$router.resolve({
                    path:"/desgin/txgl",
                    query:{idx:this.price_type,}
                })
            },
            // 添加款式
            addAccessory() {
                var obj = { name:null,module_no:'',dimension: [], photo_size:[],photo_story:[] };
                this.ruleForm.accessory.push(obj);
                console.log(this.ruleForm.accessory);
            },
            delAccessory(index) {
                this.$confirm('确认是否删除附件?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    this.$message({ type: 'success', message: '删除成功!' });
                    this.ruleForm.accessory.splice(index,1);
                });
            },
            setTpl(tpl){
                this.ruleForm.tpl_id=tpl.id;
                this.curParams= Object.entries(tpl.params);
                this.ruleForm.tpl_params=[]
                for(let _k in tpl.params){
                    this.ruleForm.tpl_params.push({
                        key:_k,
                        text:tpl.params[_k].text,
                        value:tpl.params[_k].value,
                    });
                }
                console.log(this.ruleForm.tpl_params);
            },
            preview() {
                this.$refs['ruleForm1'].validate((valid) => {
                    if (valid) {
                        this.previewSubmit();
                    }
                });
            },
            previewSubmit() {
                let _item = _.clone(this.ruleForm);
                let setObj=this.series.find((e)=> {
                    return e.set_no == this.ruleForm.set_no;
                })
                let catObj=this.catList.find((e)=> {
                    return e.id == this.ruleForm.cat_id;
                })
                let styleObj=this.styles.find((e)=> {
                    return e.id == this.ruleForm.style_no;
                })
                if (setObj) _item.set_no = setObj.name;
                _item.cat_id = catObj.name;
                _item.style_no = styleObj.name;
                _item.type = 1;// 1版权购买，2分成合作
                this.previewData = _item;
                this.previewFlag = true;
                // this.$router.push({
                //     path:"/desgin/detail/view",
                //     query:{
                //         item:encodeURI(JSON.stringify(_item)),
                //     },
                // })
            },
            checkRuleForm() {
                let attr = [];
                if (this.ruleForm.attr && this.ruleForm.attr.length>0) {
                    this.ruleForm.attr.forEach((e)=>{
                        if (e.type == "single") {
                            attr.push({
                                name: e.id,
                                values: [e.values]
                            })
                        } else if (e.type == "mutil") {
                            attr.push({
                                name: e.id,
                                values: e.values
                            })
                        }
                    })
                }
                return attr;
            },
            // 存草稿
            saveDraft() {
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                });
                let _item = _.clone(this.ruleForm);
                _item.attr = this.checkRuleForm();
                if (!_item.sname) {
                    _item.sname = _item.name;
                }
                // _item.publish = 0;
                addPriceProduct(_item).then(rst => {
                    _loading.close();
                    this.$message.success('草稿保存成功');
                    this.back();
                    // this.$router.push('/desgin/sjbq');
                }).catch(err => {
                    _loading.close();
                    this.$message.error(err.message);
                });
            },
            // 编辑保存
            editSubmit(publish) {
                if (publish==1 && this.$store.state.loginUser.comp.cert_stat!=2) {
                    this.$message.error("企业未认证");
                    return;
                } else {
                    this.$refs['ruleForm1'].validate((valid) => {
                        if (valid) {
                            if (this.product_no) {
                                let _item = _.clone(this.ruleForm);
                                _item.attr = this.checkRuleForm();
                                // _item.tpl_params = {handsel:_item.tpl_params.handsel.value};
                                _item.publish = publish;
                                console.log(_item);
                                _item.product_no = this.product_no;
                                setPriceProduct(_item).then(rst => {
                                    this.$message.success('保存成功');
                                    this.back();
                                    // this.$router.push('/desgin/sjbq');
                                }).catch(err => {
                                    this.$message.error(err.message);
                                });
                            }
                        }
                    });
                }
            },
            submitShow() {
                if (this.$store.state.loginUser.comp.cert_stat!=2) {
                    this.$message.error("企业未认证");
                    return;
                } else {
                    this.$refs['ruleForm1'].validate((valid) => {
                        if (valid) {
                            this.popTitle = "温馨提示";
                            this.popVisible = true;
                        }
                    });
                }
            },
            submitHide() {
                this.popVisible = false;
            },
            // 发布
            submitForm(){
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                });
                let _item = _.clone(this.ruleForm);
                _item.attr = this.checkRuleForm();
                if (!_item.sname) {
                    _item.sname = _item.name;
                }
                _item.publish = 1;
                console.log(_item);
                addPriceProduct(_item).then(rst => {
                    this.$message.success('上传成功');
                    _loading.close();
                    this.submitHide();
                    this.back();
                    // this.$router.push('/desgin/sjbq');
                }).catch(err => {
                    _loading.close();
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style>

</style>
<style scoped src="../style.css"></style>
<style scoped lang="less">
    .list-item {
        position:relative;
        padding:30px 0;
        border-bottom:1px solid #E6E6E6;
    }
    .list-item:last-child {
        border-bottom:0;
    }
    .add-accessory {
        height: 70px;
        border-top: 1px solid #E6E6E6;
        >span {
            font-size: 16px;
        }
        .addSeries {
            text-align: center;
            // padding: 10px 90px 0;
            cursor: pointer;
            >span {
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                color: #016FFF;
                &:first-child {
                    width: 18px;
                    height: 18px;
                    line-height: 15px;
                    text-align: center;
                    font-size: 18px;
                    color: #016FFF;
                    border: 1px solid #016FFF;
                    border-radius: 50%;
                    position: relative;
                    top: 2px;
                    margin-right: 5px;
                }
            }
        }
    }
    .el-input, .el-cascader, .el-select {
        width: 240px;
    }
</style>
<style scoped>
    .tpls{
        overflow: hidden;
    }
    .tpls-item {
        margin:0 15px;
        margin-bottom: 10px;
    }
    .tpls-item>div:first-child {
        text-align: center;
    }
    .tpls-item>div:last-child {
        line-height: 36px;
    }
    .tpls-item>div>i {
        font-size: 55px;
        color: #F66F6A;
        position: relative;
        top: 8px;
        left: -10px;
    }
    .tpls-item>>> .el-radio__label {
        padding: 0;
    }
    .tpls-item>div>a {
        margin-right: 10px;
    }
    .shopStyle {
        background: #F5F5F5;
    }
    .shopStyle-item {
        padding: 20px 10px;
    }
    .shopStyle-header {
        padding-left: 10px;
        margin-bottom: 10px;
    }
    .shopStyle-header>div {
        font-size: 14px;
        color: #4D4D4D;
    }
    .shopStyle-header>div:last-child {
        color: #CC995A;
        margin-left: 40px;
        cursor: pointer;
    }
    .input-red>>> .el-input__inner {
        border: 1px solid #F66F6A;
        padding: 0 0 0 15px;
    }
    .input-red>>> .el-form-item__error {
        left: 310px;
    }
</style>